import mixpanel from 'mixpanel-browser'

class MixpanelUtil {
	private static _instance

	constructor() {
		if (MixpanelUtil._instance) {
			return
		}

		const token = process.env.REACT_APP_MIXPANEL_TOKEN
		mixpanel.init(token, {
			track_pageview: true,
			persistence: 'localStorage',
		})

		MixpanelUtil._instance = this
	}

	track(event, props) {
		if (process.env.NODE_ENV === 'development') {
			console.log('Tracking event:', event, props)
		}
		try {
			const propsCopy = { ...props }
			mixpanel.track(event, propsCopy)
		} catch (err) {
			mixpanel.track('ERROR during track', { error: err })
			console.error(err)
		}
	}

	identify(id) {
		try {
			mixpanel.identify(id)
		} catch (err) {
			mixpanel.track('ERROR during track')
			console.error(err)
		}
	}

	// Static method to get the instance of the class
	static getInstance() {
		if (!MixpanelUtil._instance) {
			MixpanelUtil._instance = new MixpanelUtil()
		}
		return MixpanelUtil._instance
	}
}

export default MixpanelUtil
