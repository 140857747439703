import React, { useState, useRef, useEffect } from 'react'
import './App.css'
import Chat from './components/Chat'
import MixpanelUtil from './mixpanel'
import Api from './api'
import Spinner from './components/Spinner'

const DISPLAY_NAME = ''
const defaultSpreadsheetId = '136447bd-2d07-4ddb-9090-59e98e432468'
const defaultUrl =
	'https://onedrive.live.com/embed?resid=3DAF979303983E34%21131&authkey=!ACYqJewzoOEzDoc&em=2'

const mp = MixpanelUtil.getInstance()

const DEMO_USER = {
	created_at: '2024-07-08 20:44:53.135000',
	email: 'demo@rowsie.ai',
	id: '377a1153-02c2-4bf0-adc2-7c84e37180b3',
	username: 'demo@rowsie.ai',
}

const App = () => {
	const [spreadsheetId, setSpreadsheetId] = useState(defaultSpreadsheetId)
	const [embedLink, setEmbedLink] = useState(defaultUrl)

	const api = Api.getInstance()

	const handleFileUpload = async (event) => {
		const file = event.target.files[0]
		const formData = new FormData()
		formData.append('file', file) // 'file' is the key expected by the FastAPI server
		setEmbedLink('')
		setSpreadsheetId('')

		try {
			const response = await fetch(api.baseUrl + '/spreadsheets/upload', {
				method: 'POST',
				body: formData,
				// Don't set Content-Type header, let the browser set it
			})

			if (response.ok) {
				const result = await response.json()
				setSpreadsheetId(result.spreadsheet_id)
				setEmbedLink(result.embed_link)
				console.log('File uploaded successfully:', result)
			} else {
				console.error('Upload failed:', response.statusText)
			}
		} catch (error) {
			console.error('Error during file upload:', error)
		}
	}

	useEffect(() => {
		const bearer = process.env.REACT_APP_DEMO_BEARER_TOKEN
		api.token = bearer
	}, [api])

	const iframeRef = useRef(null)

	const handleTryRowsieClick = () => {
		mp.track('Try Rowsie in Excel Clicked')
		window.open(
			'https://appsource.microsoft.com/en-us/product/Office365/WA200006068',
			'_blank',
			'noopener noreferrer',
		)
	}

	return (
		<div className='maintenance-mode' style={{ textAlign: 'center', padding: '20px' }}>
			<h2>Maintenance Mode</h2>
			<p style={{ color: 'black', fontSize: '16px' }}>
				Our service is currently undergoing maintenance. Please check back later.
			</p>
		</div>
	)

	// return (
	// 	<div className='app-container'>
	// 		<header className='app-header' style={{ justifyContent: 'space-between' }}>
	// 			<div
	// 				style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
	// 				onClick={() =>
	// 					window.open('https://www.rowsie.ai', '_blank', 'noopener noreferrer')
	// 				}
	// 				onKeyUp={(e) => {
	// 					if (e.key === 'Enter' || e.key === ' ') {
	// 						window.open('https://www.rowsie.ai', '_blank', 'noopener noreferrer')
	// 					}
	// 				}}
	// 			>
	// 				<img
	// 					src={process.env.PUBLIC_URL + '/icon-600.png'}
	// 					alt='App Icon'
	// 					style={{ height: '40px', marginRight: '10px' }}
	// 				/>
	// 				<h1>Rowsie Demo</h1>
	// 			</div>
	// 			<div style={{ textAlign: 'center', paddingTop: '20px', paddingBottom: '5px' }}>
	// 				<input type='file' accept='.xlsx, .xls' onChange={handleFileUpload} />
	// 				<p style={{ color: 'white', fontSize: '12px', fontStyle: 'italic' }}>
	// 					Please do not upload any sensitive data.
	// 				</p>
	// 			</div>
	// 		</header>
	// 		{!spreadsheetId ? (
	// 			<Spinner />
	// 		) : (
	// 			<div className='content'>
	// 				<div className='excel-viewer'>
	// 					<iframe
	// 						title='Excel'
	// 						ref={iframeRef}
	// 						height='100%'
	// 						width='100%'
	// 						src={embedLink}
	// 					/>
	// 				</div>
	// 				<div className='sidebar'>
	// 					<Chat displayName={DISPLAY_NAME} spreadsheetId={spreadsheetId} />
	// 				</div>
	// 			</div>
	// 		)}
	// 		<footer
	// 			className='app-footer'
	// 			style={{
	// 				backgroundColor: '#125d56',
	// 				color: 'white',
	// 				textAlign: 'center',
	// 			}}
	// 		>
	// 			<p>
	// 				This is just a demo with limited features. For the full experience, try it in
	// 				Excel!
	// 			</p>
	// 			<button
	// 				type='button'
	// 				onClick={handleTryRowsieClick}
	// 				style={{
	// 					padding: '10px 20px',
	// 					backgroundColor: '#61dafb',
	// 					color: '#125d56',
	// 					border: 'none',
	// 					borderRadius: '5px',
	// 					cursor: 'pointer',
	// 					textDecoration: 'none',
	// 				}}
	// 			>
	// 				Try Rowsie in Excel
	// 			</button>
	// 		</footer>
	// 	</div>
	// )
}

export default App
